import mock from "../mock"

const data = {
  pricing: {
    basicPlan: {
      id: 1,
      title: "Básico",
      subtitle: "Plan básico",
      monthlyPrice: 0,
      yearlyPlan: {
        perMonth: 0,
        totalAnnual: 0
      },
      planBenefits: [
        "100 respuestas por mes",
        "Campos ilimitados"
      ]
    },
    standardPlan: {
      id: 2,
      title: "Estándar",
      subtitle: "Para profissionales",
      monthlyPrice: 49,
      yearlyPlan: {
        perMonth: 40,
        totalAnnual: 480
      },
      planBenefits: [
        "Respuestas ilimitadas",
        "Página del Instagram"
      ]
    },
    enterprisePlan: {
      id: 3,
      title: "Premium",
      subtitle: "Para especialistas",
      monthlyPrice: 99,
      yearlyPlan: {
        perMonth: 80,
        totalAnnual: 960
      },
      planBenefits: [
        "Pagos PayPal",
        "Almacenaje de 5GB"
      ]
    },
    qandA: [
      {
        question: 'Minha inscrição renova automaticamente?',
        ans: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse viverra, nulla sit amet aliquam tristique, lectus nisl scelerisque lectus, et cursus tellus erat et dui.'
      },
      {
        question: 'Posso guardar um item na intranet para todo mundo ter acesso?',
        ans: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse viverra, nulla sit amet aliquam tristique, lectus nisl scelerisque lectus, et cursus tellus erat et dui.'
      },
      {
        question: 'Posso mudar o item que comprei?',
        ans: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse viverra, nulla sit amet aliquam tristique, lectus nisl scelerisque lectus, et cursus tellus erat et dui.'
      }
    ]
  }
}

mock.onGet('/pricing/data/es').reply(() => [200, data.pricing])